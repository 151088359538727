.ats-cont {
  width: 100%;
  height: 90vh;
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ats-cont > .ats-left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding-top: 5vh;
  padding-left: 5vw;
  background: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    220deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -moz-linear-gradient(
    220deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -webkit-linear-gradient(
    220deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
}

.ats-cont > .ats-left > h2 {
  font-size: 3.3vmax;
}

.ats-cont > .ats-left > h2 > span {
  color: #3b82f6;
  font-weight: 600;
}

.ats-cont > .ats-left > p {
  font-size: 1vmax;
  font-weight: 600;
}

.ats-cont > .ats-left > .input-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40%;
  width: 100%;
  margin-top: 5vh;
}

.ats-cont > .ats-left > .input-box > .input-box1 {
  width: 67%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding-right: 2vw;
}

.ats-cont > .ats-left > .input-box > .input-box1 > p {
  font-size: 1.05vmax;
  font-weight: 600;
}

.ats-cont > .ats-left > .input-box > .input-box1 > .coolinput {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.ats-cont > .ats-left > .input-box > .input-box1 > .coolinput label.text {
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  position: relative;
  top: 15%;
  margin: 0 0 0 7px;
  padding: 0.25vh 0.5vw;
  border-radius: 0.25vmax;
  background: #3b82f6;
  width: fit-content;
}

.ats-cont
  > .ats-left
  > .input-box
  > .input-box1
  > .coolinput
  input[type="text"].input {
  padding: 2.5vh;
  font-size: 1vmax;
  border: 2px #3b82f6 solid;
  border-radius: 5px;
  background: #fff;
}

.ats-cont
  > .ats-left
  > .input-box
  > .input-box1
  > .coolinput
  input[type="text"].input:focus {
  outline: 0;
}

.ats-cont > .ats-left > .input-box > .input-box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 33%;
}

.ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label input {
  display: none;
}

.ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label svg {
  height: 3vmax;
  fill: #fff;
}

.ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label {
  cursor: pointer;
  background-color: #3b82f6;
  padding: 1vh 3vw;
  border-radius: 0.75vmax;
  border: 2px dashed #666;
  box-shadow: 0 0 200px -50px rgba(0, 0, 0, 0.5);
  color: #eee;
}

.ats-cont
  > .ats-left
  > .input-box
  > .input-box2
  > .file-upload-label
  > .file-upload-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ats-cont
  > .ats-left
  > .input-box
  > .input-box2
  > .file-upload-label
  > .file-upload-design
  > p {
  margin: 0.5vh 0;
}

.ats-cont
  > .ats-left
  > .input-box
  > .input-box2
  > .file-upload-label
  > .file-upload-design
  > .browse-button {
  background-color: #fff;
  padding: 1vh 1.5vw;
  border-radius: 0.5vmax;
  color: #3b82f6;
  transition: all 0.3s;
}

.ats-cont
  > .ats-left
  > .input-box
  > .input-box2
  > .file-upload-label
  > .file-upload-design
  > .browse-button:hover {
  scale: 1.05;
}

.ats-cont > .ats-left > .input-box > .input-box2 > .ats-btn {
  background-color: #3b82f6;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0.75vmax;
  width: 100%;
  height: 20%;
  transition: 0.3s;
}

.ats-cont > .ats-left > .input-box > .input-box2 > .ats-btn:hover {
  box-shadow: 0 0 0 5px #3b83f65f;
}

.ats-cont > .ats-right {
  width: 50%;
  height: 100%;
  background: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    -40deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -moz-linear-gradient(
    -40deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -webkit-linear-gradient(
    -40deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  user-select: none;
}

.ats-cont > .ats-right > img {
  width: 100%;
  height: 100%;
  padding: 2vmax;
  object-fit: contain;
  pointer-events: none;
}

.ats-result {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ats-result > .result-left {
  width: 50%;
  height: 100%;
  background: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    140deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -moz-linear-gradient(
    140deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -webkit-linear-gradient(
    140deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
}

.ats-result > .result-right {
  width: 50%;
  height: 100%;
  background: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    40deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -moz-linear-gradient(
    40deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
  background: -webkit-linear-gradient(
    40deg,
    hsla(0, 0%, 100%, 1) 0,
    hsla(0, 0%, 100%, 1) 16%,
    hsla(0, 0%, 100%, 1) 42%,
    hsla(217, 91%, 60%, 1) 100%
  );
}

.ats-result > .result-float {
  position: absolute;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ats-result > .result-float > .float-left {
  width: 30%;
  height: 100%;
  border-radius: 1vmax;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
  padding-left: 2vw;
}

.ats-result > .result-float > .float-left > h3 {
  text-decoration: underline #3b82f6;
  text-underline-offset: 3px;
}

.ats-result > .result-float > .float-left > button {
  background: #3b82f6;
  color: #fff;
  padding: 1.5vh 2vw;
  border-radius: 0.5vmax;
}

.ats-result > .result-float > .float-left > .score-container {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ats-result > .result-float > .float-left > .score-container > strong {
  font-size: 1.1vmax;
  color: #3b82f6;
}

.ats-result > .result-float > .float-left > .score-container > .progress-ring {
  position: relative;
}

.ats-result
  > .result-float
  > .float-left
  > .score-container
  > .progress-ring
  > .progress-circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.ats-result > .result-float > .float-right {
  width: 65%;
  height: 100%;
  border-radius: 1vmax;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.ats-result > .result-float > .float-right > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.ats-result > .result-float > .float-right > ul > .suggestion-item {
  height: 20%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.ats-result > .result-float > .float-right > ul > .suggestion-item > strong {
  color: #3b82f6;
}

.ats-result > .result-float > .float-right > ul > .suggestion-item > span {
  font-size: 1vmax;
}

.ats-result > .result-float > .float-right > ul > .suggestion-item > .example {
  font-style: italic;
  color: #666;
  position: relative;
}

.ats-result
  > .result-float
  > .float-right
  > ul
  > .suggestion-item
  > .example
  > .blurred-text {
  color: transparent;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  user-select: none;
}

.ats-result
  > .result-float
  > .float-right
  > ul
  > .suggestion-item
  > .example
  > .blurred-text::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  color: transparent;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.ats-result
  > .result-float
  > .float-right
  > ul
  > .suggestion-item
  > .unlock-button {
  display: block;
  position: absolute;
  bottom: 10%;
  right: 10%;
  background-color: #3b82f6;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5vmax 1vmax;
  cursor: pointer;
  font-size: 0.9vmax;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none;
}

.ats-result
  > .result-float
  > .float-right
  > ul
  > .suggestion-item:active
  > .unlock-button,
.ats-result
  > .result-float
  > .float-right
  > ul
  > .suggestion-item:hover
  > .unlock-button {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.ats-result > .result-float > .float-right > button {
  background-color: #3b82f6;
  color: #fff;
  padding: 1vh 2.5vw;
  border-radius: 0.5vmax;
}

@media (max-width: 600px) {
  .ats-cont {
    flex-direction: column;
    height: 70vh;
    margin-top: 5vh;
  }

  .ats-cont > .ats-right {
    display: none;
  }

  .ats-cont > .ats-left {
    width: 100%;
    padding: 0 5vw;
    align-items: start;
    justify-content: space-evenly;
    gap: 1vh;
  }

  .ats-cont > .ats-left > h2 {
    font-size: 3vmax;
    margin: 0;
    margin-top: 1vh;
  }

  .ats-cont > .ats-left > p {
    font-size: 1.75vmax;
    margin: 0;
  }

  .ats-cont > .ats-left > .input-box {
    flex-direction: column;
    height: 60%;
    margin: 0;
  }

  .ats-cont > .ats-left > .input-box > .input-box1 {
    height: 30%;
    padding: 0;
    width: 100%;
  }

  .ats-cont > .ats-left > .input-box > .input-box1 > p {
    margin: 0;
    font-size: 1.4vmax;
  }

  .ats-cont > .ats-left > .input-box > .input-box1 > .coolinput {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box1
    > .coolinput
    input[type="text"].input {
    width: 100%;
    height: 30%;
    font-size: 1.5vmax;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 {
    width: 100%;
    height: 60%;
    padding: 0;
    justify-content: space-between;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label {
    cursor: pointer;
    background-color: #3b82f6;
    padding: 2vh 0;
    width: 50%;
    border-radius: 0.75vmax;
    border: 2px dashed #666;
    box-shadow: 0 0 200px -50px rgba(0, 0, 0, 0.5);
    color: #eee;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box2
    > .file-upload-label
    > .file-upload-design {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box2
    > .file-upload-label
    > .file-upload-design
    > p {
    font-size: 1.75vmax;
    margin: 0.5vh 0;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box2
    > .file-upload-label
    > .file-upload-design
    > .browse-button {
    background-color: #fff;
    padding: 1vh 1.5vw;
    border-radius: 0.5vmax;
    color: #3b82f6;
    transition: all 0.3s;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label {
    font-size: 1.5vmax;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label svg {
    height: 5vw;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .ats-btn {
    height: 25%;
    padding: 1.5vh;
    font-size: 2vmax;
  }

  .ats-result {
    flex-direction: column;
    height: 130vh;
  }

  .ats-result > .result-left {
    width: 100%;
  }

  .ats-result > .result-right {
    width: 100%;
    background: hsla(0, 0%, 100%, 1);
    background: linear-gradient(
      220deg,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 16%,
      hsla(0, 0%, 100%, 1) 42%,
      hsla(217, 91%, 60%, 1) 100%
    );
    background: -moz-linear-gradient(
      220deg,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 16%,
      hsla(0, 0%, 100%, 1) 42%,
      hsla(217, 91%, 60%, 1) 100%
    );
    background: -webkit-linear-gradient(
      220deg,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 16%,
      hsla(0, 0%, 100%, 1) 42%,
      hsla(217, 91%, 60%, 1) 100%
    );
  }

  .ats-result > .result-float {
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    height: 95%;
  }

  .ats-result > .result-float > .float-left,
  .ats-result > .result-float > .float-right {
    width: 100%;
    height: 47.5%;
  }

  .ats-result > .result-float > .float-left {
    align-items: center;
    width: 95%;
  }

  .ats-result > .result-float > .float-left > h3 {
    font-size: 3vmax;
    letter-spacing: 0.3vmax;
  }

  .ats-result > .result-float > .float-left > .score-container > strong {
    font-size: 1.7vmax;
  }

  .ats-result > .result-float > .float-right {
    width: 95%;
    padding: 1vmax;
  }

  .ats-result > .result-float > .float-right > ul {
    justify-content: space-evenly;
  }

  .ats-result > .result-float > .float-right > ul > .suggestion-item {
    width: 100%;
  }

  .ats-result > .result-float > .float-right > ul > .suggestion-item > strong {
    font-size: 1.25vmax;
  }

  .ats-result > .result-float > .float-right > ul > .suggestion-item > span {
    font-size: 1.1vmax;
  }

  .ats-result
    > .result-float
    > .float-right
    > ul
    > .suggestion-item
    > .example {
    font-size: 1.1vmax;
  }

  .ats-result
    > .result-float
    > .float-right
    > ul
    > .suggestion-item
    > .unlock-button {
    padding: 0.8vmax 1.5vmax;
    font-size: 1vmax;
    bottom: 5%;
    right: 5%;
  }

  .ats-result > .result-float > .float-right > button {
    padding: 1.2vh 3vw;
    border-radius: 0.6vmax;
  }
}
@media (max-width: 400px) {
  .ats-cont {
    flex-direction: column;
    height: 80vh;
    margin-top: 5vh;
  }

  .ats-cont > .ats-right {
    display: none;
  }

  .ats-cont > .ats-left {
    width: 100%;
    padding: 0 5vw;
    align-items: start;
    justify-content: space-evenly;
    gap: 1vh;
  }

  .ats-cont > .ats-left > h2 {
    font-size: 4vmax;
    margin: 0;
    margin-top: 1.5vh;
  }

  .ats-cont > .ats-left > p {
    font-size: 2vmax;
    margin: 0;
  }

  .ats-cont > .ats-left > .input-box {
    flex-direction: column;
    height: 60%;
    margin: 0;
  }

  .ats-cont > .ats-left > .input-box > .input-box1 {
    height: 30%;
    padding: 0;
    width: 100%;
  }

  .ats-cont > .ats-left > .input-box > .input-box1 > p {
    margin: 0;
    font-size: 1.5vmax;
  }

  .ats-cont > .ats-left > .input-box > .input-box1 > .coolinput {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ats-cont > .ats-left > .input-box > .input-box1 > .coolinput label.text {
    top: 12%;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box1
    > .coolinput
    input[type="text"].input {
    width: 100%;
    height: 30%;
    font-size: 1.5vmax;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 {
    width: 100%;
    height: 60%;
    padding: 0;
    justify-content: space-between;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label {
    cursor: pointer;
    background-color: #3b82f6;
    padding: 2vh 0;
    width: 50%;
    border-radius: 0.75vmax;
    border: 2px dashed #666;
    box-shadow: 0 0 200px -50px rgba(0, 0, 0, 0.5);
    color: #eee;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box2
    > .file-upload-label
    > .file-upload-design {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box2
    > .file-upload-label
    > .file-upload-design
    > p {
    font-size: 1.75vmax;
    margin: 0.5vh 0;
  }

  .ats-cont
    > .ats-left
    > .input-box
    > .input-box2
    > .file-upload-label
    > .file-upload-design
    > .browse-button {
    background-color: #fff;
    padding: 1vh 1.5vw;
    border-radius: 0.5vmax;
    color: #3b82f6;
    transition: all 0.3s;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label {
    font-size: 1.5vmax;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .file-upload-label svg {
    height: 5vw;
  }

  .ats-cont > .ats-left > .input-box > .input-box2 > .ats-btn {
    height: 25%;
    padding: 1.5vh;
    font-size: 2vmax;
  }

  .ats-result {
    flex-direction: column;
    height: 150vh;
  }

  .ats-result > .result-left {
    width: 100%;
  }

  .ats-result > .result-right {
    width: 100%;
    background: hsla(0, 0%, 100%, 1);
    background: linear-gradient(
      220deg,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 16%,
      hsla(0, 0%, 100%, 1) 42%,
      hsla(217, 91%, 60%, 1) 100%
    );
    background: -moz-linear-gradient(
      220deg,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 16%,
      hsla(0, 0%, 100%, 1) 42%,
      hsla(217, 91%, 60%, 1) 100%
    );
    background: -webkit-linear-gradient(
      220deg,
      hsla(0, 0%, 100%, 1) 0,
      hsla(0, 0%, 100%, 1) 16%,
      hsla(0, 0%, 100%, 1) 42%,
      hsla(217, 91%, 60%, 1) 100%
    );
  }

  .ats-result > .result-float {
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    height: 95%;
  }

  .ats-result > .result-float > .float-left,
  .ats-result > .result-float > .float-right {
    width: 100%;
    height: 47.5%;
  }

  .ats-result > .result-float > .float-left {
    align-items: center;
    padding: 2.5vmax 0;
    width: 95%;
  }

  .ats-result > .result-float > .float-left > h3 {
    font-size: 3vmax;
    letter-spacing: 0.3vmax;
  }

  .ats-result > .result-float > .float-left > .score-container > strong {
    font-size: 1.7vmax;
  }

  .ats-result > .result-float > .float-right {
    width: 95%;
    padding: 1vmax;
  }

  .ats-result > .result-float > .float-right > ul {
    justify-content: space-evenly;
  }

  .ats-result > .result-float > .float-right > ul > .suggestion-item {
    width: 100%;
  }

  .ats-result > .result-float > .float-right > ul > .suggestion-item > strong {
    font-size: 1.25vmax;
  }

  .ats-result > .result-float > .float-right > ul > .suggestion-item > span {
    font-size: 1.1vmax;
  }

  .ats-result
    > .result-float
    > .float-right
    > ul
    > .suggestion-item
    > .example {
    font-size: 1.1vmax;
  }

  .ats-result
    > .result-float
    > .float-right
    > ul
    > .suggestion-item
    > .unlock-button {
    padding: 0.8vmax 1.5vmax;
    font-size: 1vmax;
    bottom: 5%;
    right: 5%;
  }

  .ats-result > .result-float > .float-right > button {
    padding: 1.2vh 3vw;
    border-radius: 0.6vmax;
  }
}
